<template>
  <AdminUserForm
    :user="user"
    title="Modify Admin User Profile"
    description="Modify an existing admin user. Only user role and name can be modified, as well as their associated parks."
    @submit="saveUser"
    @loading="setLoading"
  />
</template>

<script>
import AdminUserForm from "@/components/admin/AdminUserForm.vue";
import AdminUserService from "@/services/admin/AdminUserService.js";
export default {
  name: "AdminUserModify",
  title: "Admin - User Modify",
  props: {
    userId: { type: String, default: "" }
  },
  components: {
    AdminUserForm
  },
  data() {
    return {
      user: {},
      loading: false
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    setLoading(val) {
      this.loading = val;
    },
    async getUser() {
      const userService = new AdminUserService(this.tenantId);
      const response = await userService.getUser(this.userId);
      this.user = {
        id: response.id,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        username: response.username,
        userRole: response.userRoleId,
        userParks: response.locations
      };
    },
    saveUser(request) {
      this.loading = true;
      const userService = new AdminUserService(this.tenantId);
      userService
        .updateUser(request)
        .then(response => {
          if (response?.statusCode == "Success") {
            this.$router.push("/admin/user-search").catch(() => {});
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-success",
              message: "User has been modified.",
              layer: "admin"
            });
          } else {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.getUser();
  }
};
</script>
